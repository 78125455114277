h1, h2, h3, p {
  margin: 0px;
}

.hrContainer {
  padding-left: 1rem;
  padding-right: 1rem;
}

hr {
  border-color: #EEEEEE25;
  border-width: 1px;
  margin: 0px;
}

navbar {
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

navbar a {
  text-decoration: none;
  color: black;
  margin-left: 1rem;
}

.section {
  padding: 1rem;
}

.primaryColor {
  color: #0F4C81;
}

#heroContainer {
  min-height: 100vh;
  width: 100%;
}

#heroCenteredContainer {
  margin-top: 30%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#heroCenteredContainer img {
  width: 90%;
  margin-bottom: 2rem;
}

#heroCenteredContainer h2 {
  color: #0F4C81;
}

#heroCenteredContainer h2, #heroCenteredContainer p  {
  width: 90%;
  text-align: left;
}

#servicesContainer p {
  margin-bottom: 1rem;
}

.serviceContainer {
  display: flex;
  flex-direction: row;
  align-items: start;
  margin-bottom: 1rem;
}

.serviceContainer .serviceIconContainer {
  margin-right: 1rem;
}

.serviceContainer .serviceIconContainer svg {
  width: 75px;
  height: 75px;
  color: #0F4C81;
}

#servicesContainer .serviceContainer p {
  margin-bottom: 0px;
}

#contactContainer p {
  margin-bottom: 1rem;
}

#contactForm {
  display: flex;
  flex-direction: column;
}

#contactForm input, textarea {
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  border-radius: 10px;
  border: 1px solid #000000;
}

#contactForm button {
  padding: 0.5rem;
  background-color: #000000;
  color: white;
  border: none;
  cursor: pointer;
  font-weight: bold;
  border-radius: 10px;
}

footer {
  padding: 1rem;
}

footer h1 {
  margin-bottom: 0.5rem;
}

#footerLinks {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}

#footerLinks a {
  color: #000000;
  text-decoration: none;
  margin-bottom: 0.5rem;
}

#footerSocials {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#footerSocials a.footerSocialIcon {
  display: flex;
  justify-items: center;
  align-items: center;
  background-color: #0F4C81;
  color: white;
  padding: 0.5rem;
  margin-right: 0.5rem;
  border-radius: 50%;
}

#footerSocials a.footerSocialIcon svg {
  width: 1.5rem;
  height: 1.5rem;
}

footer p {
  text-align: left;
}

@media only screen and (min-width: 768px) {
  #heroCenteredContainer {
    margin-top: 5%;
  }

  #heroCenteredContainer img {
    width: 25%;
    margin-bottom: 1rem;
  }

  #heroCenteredContainer h2, #heroCenteredContainer p  {
    width: 100%;
    text-align: center;
  }
}